<template>
  <div class="d-inline">
    <button class="btn btn-primary ml-2" @click="show" v-if="btn">
      <span class>{{ btn }}</span>
    </button>
    <transition name="fade">
      <div class="modal fade show" :id="id" tabindex="-1" role="dialog" v-if="showModal">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content" :style="{ backgroundColor: bg }">
            <div class="modal-header border-0">
              <div class>
                <h3 class="m-0">{{ title }}</h3>
                <span class="text-muted">
                  <small>{{ subtitle }}</small>
                </span>
              </div>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click.stop="hide">
                <span aria-hidden="true" :class="text">&times;</span>
              </button>
            </div>
            <div class="modal-body d-flex flex-row align-items-center">
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="d-none d-lg-block" v-if="img">
                  <img :src="img" style="height: 15rem" class="m-5" />
                </div>
              </div>
              <div class="w-100" :class="{ 'pl-md-5 pr-md-5 p-3': padding }">
                <slot></slot>
              </div>
            </div>
            <div class="p-3 text-right" v-if="btns">
              <button type="button" class="btn btn-secondary mr-1" data-dismiss="modal" @click="hide">Annuler</button>
              <button type="button" class="btn btn-primary" @click="$emit('store')">Enregistrer</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {},
  name: "Modal",
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: false,
      default: "",
    },
    btn: {
      type: String,
      required: false,
      default: "Ajouter",
    },
    padding: {
      type: Boolean,
      required: false,
      default: true,
    },
    btns: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    hide: function () {
      document.getElementById(this.id).classList.remove("show");
      document.getElementById(this.id).setAttribute("aria-hidden", "true");
      // document.getElementById(this.id).style.display = "none";
      const mbd = document.getElementsByClassName("modal-backdrop");
      if (mbd.length > 0) {
        document.body.removeChild(mbd[0]);
      }
      document.body.classList.remove("modal-open");
      this.showModal = false;
    },
    show: function () {
      this.showModal = true;
      let backdrop = document.createElement("div");
      backdrop.classList.add("modal-backdrop");
      backdrop.classList.add("fade");
      backdrop.classList.add("show");
      document.body.append(backdrop);
      document.body.classList.add("modal-open");
    },
  },
  computed: {
    bg: function () {
      return this.$store.getters["colors/colors"].color2;
    },
    text: function () {
      if (this.$store.getters["colors/darkMode"]) {
        return "text-white";
      }
      return "text-black";
    },
  },
};
</script>
<style lang="css">
.modal {
  display: block;
}
</style>
